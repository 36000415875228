













































import {
  computed,
  defineComponent,
  reactive,
  watch,
} from "@vue/composition-api"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import Card from "@/components/cards/Card.vue"
import { i18nContainer } from "@/containers/I18nContainer"
import RichEditor from "@/components/textAreas/RichEditor.vue"
import { goToRoute } from "@/router"
import RouteName from "@/constants/RouteName"

interface State {
  isOpenDetail: boolean
}

export default defineComponent({
  props: {
    dateTitle: String,
    meetingTitle: String,
    memo: Object,
    parentId: {
      type: String,
      required: true,
    },
    meetingId: {
      type: String,
      required: true,
    },
    isAllOpen: Boolean,
  },
  components: {
    I18nFormattedMessage,
    Card,
    RichEditor,
  },
  setup(props, { emit }) {
    const { formatMessage } = i18nContainer.useContainer()

    const state = reactive<State>({
      isOpenDetail: true,
    })

    const goToMemoPage = () => {
      goToRoute({
        name: RouteName.InMeeting,
        params: { parentId: props.parentId, id: props.meetingId },
      })
    }

    const isShow = computed<boolean>(() => {
      return state.isOpenDetail
    })

    const isMemoEmpty = computed<boolean>(() => {
      return (
        !props.memo?.content ||
        props.memo?.content.length === 0 ||
        // {"type":"doc","content":[{"type":"paragraph"}]} <= 可能性としておこる空判定
        (props.memo?.content.length === 1 &&
          props.memo?.content[0].type === "paragraph" &&
          !props.memo?.content[0].content)
      )
    })

    const changeActiveState = () => {
      state.isOpenDetail = !state.isOpenDetail
      emit("activeStateChange", state.isOpenDetail)
    }

    watch(
      () => props.isAllOpen,
      newValue => {
        if (newValue === undefined) return
        state.isOpenDetail = newValue
      }
    )

    return {
      state,
      i18nFormattedMessage: formatMessage,
      goToMemoPage,
      isShow,
      changeActiveState,
      isMemoEmpty,
    }
  },
})
