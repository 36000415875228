
























































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
  ref,
} from "@vue/composition-api"
import { TypedComponentProps } from "@/vue-props-types"
import I18nFormattedMessage from "@/components/i18n/I18nFormattedMessage.vue"
import DefaultButton from "@/components/buttons/DefaultButton.vue"
import Loading from "@/components/Loading.vue"
import { teamsContextContainer } from "@/containers/TeamsContextContainer"
import WithBackPageLayout from "@/layouts/WithBackPageLayout.vue"
import NotificationCard from "./MemoSummaryCard.vue"
import { Props } from "./types"
import { Meeting } from "@/models/Meeting"
import MemoSummaryCard from "./MemoSummaryCard.vue"
import DateTime from "@/models/DateTime"
import { meetingContainer } from "@/containers/MeetingsContainer"
import { projectsContainer } from "@/containers/ProjectsContainer"
import { meetingOrder } from "@/utilities/Order"

interface State {
  meetings: Array<Meeting> | null
  isAllOpen: boolean
}

const propsOptions = {
  id: {
    type: String,
    required: true,
  },
}

export default defineComponent<TypedComponentProps<Props, typeof propsOptions>>(
  {
    props: propsOptions,
    components: {
      I18nFormattedMessage,
      DefaultButton,
      NotificationCard,
      WithBackPageLayout,
      MemoSummaryCard,
      Loading,
    },
    setup(props) {
      const state = reactive<State>({
        meetings: null,
        isAllOpen: true,
      })
      const isLoading = ref(true)
      const { getContext } = teamsContextContainer.useContainer()
      const {
        state: meetingState,
        getAndSetMeetingDetails,
      } = meetingContainer.useContainer()
      const { getProjectAsync } = projectsContainer.useContainer()

      const getDateTitle = (start: DateTime, end: DateTime): string => {
        const date = start.toDateStringSlash()
        const dateTime = `${date} (${start.toJpDayOfWeek()}) ${start.toJpTimeString()} ~ ${end.toJpTimeString()}`

        return dateTime
      }

      //各メモの開閉状況を知るため、isActiveプロパティを追加した配列を作成
      const meetingWithActive = computed(() => {
        return (
          state.meetings?.map(m => {
            return { ...m, ...{ isActive: true } }
          }) || []
        )
      })

      const onActiveStateChange = (meetingId: string, isActive: boolean) => {
        if (!meetingWithActive.value) return
        const index = meetingWithActive.value.findIndex(m => m.id === meetingId)
        meetingWithActive.value[index].isActive = isActive

        const itemCount = meetingWithActive.value.length
        const activeCount = meetingWithActive.value.filter(
          m => m.isActive === true
        ).length

        if (activeCount === 0) {
          //全てのitemが閉じられている場合
          state.isAllOpen = false
        } else if (activeCount === itemCount) {
          //全てのitemが開いている場合
          state.isAllOpen = true
        }
      }

      const changeAllActiveState = () => {
        if (!meetingWithActive.value) return

        state.isAllOpen = !state.isAllOpen

        meetingWithActive.value.forEach(m => (m.isActive = state.isAllOpen))
      }

      watch(
        () => props.id,
        newValue => {
          const now = new DateTime(new Date())
          const meetingStructure = meetingState.meetings.value?.find(
            m => m.id === newValue
          )
          if (meetingStructure) {
            state.meetings = meetingStructure?.meetings
              ?.sort(meetingOrder)
              .filter(m => m.endTime.isSameOrBefore(now))
          }
        }
      )

      onMounted(async () => {
        const context = await getContext()
        const project = await getProjectAsync(context.entityId)
        const now = new DateTime(new Date())
        isLoading.value = true
        let targets = meetingState.meetings.value
        if (targets === null) {
          targets = await getAndSetMeetingDetails(
            project.siteId,
            context.entityId
          )
        }
        const meetingStructure = targets.find(m => m.id === props.id)
        if (meetingStructure) {
          state.meetings = meetingStructure?.meetings
            ?.sort(meetingOrder)
            .filter(m => m.endTime.isSameOrBefore(now))
        }
        isLoading.value = false
      })

      return {
        state,
        getDateTitle,
        meetings: meetingWithActive,
        onActiveStateChange,
        changeAllActiveState,
        isLoading,
      }
    },
  }
)
